import React, { useEffect, useRef, useState } from 'react';

import NextNavLink from '../../util/NextNavLink';
function TopHeader({ menuItems }) {
    return (
        <div className="py-2.5 bg-primary-light">
            <div className="container">
                <div className="flex justify-end font-medium">
                    {menuItems.map((item, i) => (
                        <span
                            className={` ${
                                i + 1 === menuItems.length
                                    ? 'pl-1 sm:pl-6'
                                    : 'px-1 sm:px-6 border-r border-primary'
                            }`}
                        >
                            <NextNavLink
                                activeClassName="font-bold lg:border-b-2 border-b-2"
                                key={i}
                                href={item.path || item.url}
                            >
                                <a
                                    className={`transition md:text-18 sm:text-14 text-12 text-primary leading-21 border-primary`}
                                >
                                    {item.title}
                                </a>
                            </NextNavLink>
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TopHeader;
