import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';
const Plus = ({}) => {
    return <img src="/images/plus.png" />;
};
const Minus = ({}) => {
    return <img src="/images/minus.png" />;
};
import { ContactForm } from './form';
export const Contact = ({
    sectionTitle = '',
    content1 = '',
    content2,
    className,
    anchor,
    faqs = []
}) => {
    return (
        <div
            className={`relative flex items-center py-16 contactBlock ${
                className ? className : ''
            }`}
        >
            <div className="container">
                <div className="grid gap-10 md:grid md:grid-cols-2">
                    <div>
                        <h3
                            className="mb-1 font-medium text-20 leading-25"
                            dangerouslySetInnerHTML={{
                                __html: sectionTitle
                            }}
                        />
                        {/*   <div
                            className="mb-3 font-bold text-25 leading-28 text-primary-500"
                            dangerouslySetInnerHTML={{
                                __html: title
                            }}
                        /> */}
                        <div
                            className=" text-16.6 leading-25"
                            dangerouslySetInnerHTML={{
                                __html: content1
                            }}
                        />
                        <hr className="my-8 border-t-4 border-dotted border-primary-500" />
                        {/* <h5
                            className="mb-1 font-medium text-20 leading-25"
                            dangerouslySetInnerHTML={{
                                __html: sectionTitle
                            }}
                        />
                        <div
                            className="mb-3 font-bold text-25 leading-28 text-primary-500"
                            dangerouslySetInnerHTML={{
                                __html: title
                            }}
                        /> */}
                        <div
                            className=" text-16.6 leading-25"
                            dangerouslySetInnerHTML={{
                                __html: content2
                            }}
                        />
                        <hr className="my-8 border-t-4 border-dotted border-primary-500" />
                        <h5
                            className="my-8 font-medium text-20 leading-25"
                            dangerouslySetInnerHTML={{
                                __html:
                                    'Frequently Asked Questions About EpiSwitch<sup>®</sup> <strong>CiRT<strong>'
                            }}
                        />
                        <Accordion className="flex flex-col" allowZeroExpanded>
                            {faqs.map((faq, i) => (
                                <div className="w-full">
                                    <AccordionItem
                                        key={i}
                                        className={`border-3 border-primary-500 mb-6`}
                                    >
                                        <AccordionItemHeading>
                                            <AccordionItemButton className="flex items-center px-4 py-3 font-semibold outline-none items-cente text-20 text-primary-500 leading-25">
                                                <AccordionItemState>
                                                    {({ expanded }) =>
                                                        expanded ? (
                                                            <Minus />
                                                        ) : (
                                                            <Plus />
                                                        )
                                                    }
                                                </AccordionItemState>
                                                <span className="ml-3"
                                                    dangerouslySetInnerHTML={{__html: faq.faq_heading}}>
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: faq.faq_text
                                                }}
                                                className="px-4 py-3 font-medium text-16 leading-26"
                                            />
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </div>
                            ))}
                        </Accordion>
                    </div>
                    <div>
                        <h5 className="mb-4 font-bold text-25 text-primary-500">
                            How can we help you?
                        </h5>
                        <ContactForm />
                    </div>
                </div>
            </div>
        </div>
    );
};
