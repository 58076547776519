import React from 'react';
import { Contact } from '../../components/cst/Contact';
import { extractRepeaterField } from '../../util/blocks';

function ACFContactBlock({ className, anchor, data, pageProps }) {
    const { top_heading, text_block_1, text_block_2 } = data;
    let items = extractRepeaterField(data, 'faq');

    return (
        <>
            <Contact
                sectionTitle={top_heading}
                content1={text_block_1}
                content2={text_block_2}
                faqs={items}
                className={className}
                anchor={anchor}
            />
        </>
    );
}

export default ACFContactBlock;
