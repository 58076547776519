import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

const videoModalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
const ctaModalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '85%',
        maxWidth: '1200px',
        borderRadius: '30px',
        padding: '15px'
    }
};

const closeButtonStyle = {
    backgroundColor: 'white',
    borderRadius: '50%',
    color: '#3b5ca3',
    right: '15px',
    width: '30px',
    height: '30px',
    position: 'absolute',
    boxShadow: "5px 8px 15px 3px rgba(0, 0, 0, 0.1)",
    fontWeight: 'bold'
}

export const Hero = ({top_header, title, text = '', image, cta = [], cta_video = [], pop_up_ctas, gradient_overlay }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [ctaModalIsOpen, setCtaModalIsOpen] = useState([]);
    function handleModal(e) {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(!modalIsOpen);
    }
       
    
    useEffect(() => {
        pop_up_ctas.map((cta, i) => {
            let data = [...ctaModalIsOpen];
            data[i] = false;
        });
    }, );
    
    let handleCTAModals = useCallback((e, i) => {
        e.preventDefault();
        e.stopPropagation();
        let data = [...ctaModalIsOpen];
        data[i] = !data[i];
        setCtaModalIsOpen(data);
    }, [ctaModalIsOpen, setCtaModalIsOpen]);
    
    return (
        <div
            className="relative flex items-center bg-cover"
            style={{
                backgroundImage: `url(${image})`
            }}
        >
            <div
                className={`container ${
                    title ? 'py-32' : 'py-20 pb-32'
                } bg-cover`}
            >
                <div className="grid grid-cols-12">
                    <div className="col-span-12 md:col-span-7 sm:col-span-10">
                        {top_header && (
                            <h2
                                className="mb-2 font-medium text-white text-25 leading-33 text-shadow"
                                dangerouslySetInnerHTML={{
                                    __html: top_header
                                }}
                            />
                        )}
                        
                        {title && (
                            <h1
                                className="my-2 font-semibold text-white uppercase text-50 leading-50 text-shadow"
                                dangerouslySetInnerHTML={{
                                    __html: title
                                }}
                            />
                        )}
                        <p
                            dangerouslySetInnerHTML={{ __html: text }}
                            className="mb-8 font-medium text-white text-25 leading-33 text-shadow xl:w-5/6 2xl:w-7/12"
                        />
                        {pop_up_ctas.map((cta, i) => (
                            <>
                                <Modal
                                    isOpen={ctaModalIsOpen[i]}
                                    onRequestClose={(e) => handleCTAModals(e, i)}
                                    style={ctaModalStyle}
                                >
                                    <button style={closeButtonStyle}
                                            onClick={(e) => handleCTAModals(e, i)}
                                    >X</button>
                                    <div className="flex border-8 rounded-30 py-3 px-5 border-themeBlue flex-wrap text-20">
                                        <div className="w-full md:w-3/5">
                                            <span className="uppercase font-bold">{cta.title}</span>
                                            <p className="font-medium" dangerouslySetInnerHTML={{
                                                __html: cta.content
                                            }}/>
                                            <p className="font-bold" style={{color: '#519bff'}} dangerouslySetInnerHTML={{
                                                __html: cta.cta
                                            }}/>
                                        </div>
                                        <div className="w-full md:w-2/5 p-5 xs:hidden">
                                            <img src={cta.image.mediaItemUrl} alt={cta.image.altText}/>
                                        </div>
                                    </div>
                                </Modal>

                                <a
                                    onClick={(e) => handleCTAModals(e, i)}
                                    href=""
                                    className="inline-block px-4 py-2 mb-4 font-bold text-white uppercase transition border-white shadow-md border-3 md:text-20 text-16 rounded-30 text-shadow hover:bg-white hover:text-primary"
                                >
                                    {cta.title}
                                </a>


                            </>


                        ))}



                        {cta_video.url && (
                            <>
                                <Modal
                                    isOpen={modalIsOpen}
                                    onRequestClose={() => {
                                        setIsOpen(false);
                                    }}
                                    style={videoModalStyle}
                                >
                                    <ReactPlayer
                                        url={cta_video.url}
                                        autoplay
                                        controls
                                    />
                                </Modal>

                                <a
                                    onClick={(e) => handleModal(e)}
                                    href=""
                                    className="inline-block px-4 py-2 mb-4 font-bold text-white uppercase transition border-white shadow-md border-3 md:text-20 text-16 rounded-30 text-shadow hover:bg-white hover:text-primary"
                                >
                                    {cta_video.title}
                                </a>
                            </>
                        )}

                        {cta && (
                            <div>
                                <a
                                    target={cta.target}
                                    href={cta.url}
                                    className="inline-block px-4 py-2 font-bold text-white uppercase transition border-white shadow-md border-3 md:text-20 text-16 rounded-30 text-shadow hover:bg-white hover:text-primary"
                                >
                                    {cta.title}
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
