import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import SwiperCore, { Pagination, Navigation } from 'swiper';
SwiperCore.use([Pagination, Navigation]);
Modal.setAppElement('main');
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
export const TwoColumn = ({
    title,
    sectionTitle,
    description,
    heading,
    mainHeading,
    mainContent,
    cta = [],
    images = [],
    isWide = 0,
    colSize,
    className,
    anchor,
    swap,
    progressBars
}) => {
    let leftCol = 6,
        rightCol = 6;
    if (colSize === '30/70') {
        leftCol = 4;
        rightCol = 8;
    } else if (colSize === '40/60') {
        leftCol = 5;
        rightCol = 7;
    } else if (colSize === '50/50') {
        leftCol = 6;
        rightCol = 6;
    } else if (colSize === '60/40') {
        leftCol = 7;
        rightCol = 5;
    } else if (colSize === '70/30') {
        leftCol = 8;
        rightCol = 4;
    }
    const [modalIsOpen, setIsOpen] = useState(false);
    function handleModal(e) {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(!modalIsOpen);
    }
    return (
        <>
            <div
                id={anchor ? anchor : ''}
                className={`block md:grid items-center ${
                    isWide === 1 ? '' : 'py-20'
                } ${className ? className : ''} `}
            >
                <div
                    className={`${
                        isWide === '1' ? '' : 'container'
                    } text-center md:text-left`}
                >
                    {title && (
                        <h3
                            className="mb-1 font-medium text-20 leading-25"
                            dangerouslySetInnerHTML={{
                                __html: title
                            }}
                        />
                    )}
                    {sectionTitle && (
                        <div
                            className="mb-4 font-semibold text-25 leading-28 text-primary-500"
                            dangerouslySetInnerHTML={{
                                __html: sectionTitle
                            }}
                        />
                    )}
                    {description && (
                        <div
                            className="mb-10 font-medium text-17 leading-30"
                            dangerouslySetInnerHTML={{
                                __html: description
                            }}
                        />
                    )}
                    <div className="mb-6 -mx-4 md:grid md:grid-cols-12 md:mb-0 pb-8">
                        {progressBars &&
                            progressBars.map((progress, i) => (
                                <div
                                    key={i}
                                    className={`md:col-span-${
                                        i % 2 === 0 ? '5' : '7'
                                    } px-4 py-2`}
                                >
                                    <div className="items-center text-left md:justify-end md:flex">
                                        <span className="mr-3 italic text-primary-500 text-22">
                                            {progress.bar_title}
                                        </span>
                                        <div className="relative flex-1 block max-w-sm p-3 overflow-hidden border-3 border-primary rounded-30 ">
                                            <div
                                                className="flex items-center absolute will-change-transform top-0 left-0 h-full pr-3 font-semibold text-right text-white transform -skew-x-12 text-20 bg-gradient-to-r from-white to-primary"
                                                style={{
                                                    width: `${Number(
                                                        progress.bar_progress
                                                    )}%`
                                                }}
                                            >
                                               <span className="w-full">{progress.bar_progress}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div
                        className={`lg:grid gap-4 lg:grid-cols-12 ${
                            swap === '1' ? 'lg:grid-flow-col-dense' : ''
                        }`}
                    >
                        <div
                            className={`md:col-span-${leftCol} ${
                                swap === '1'
                                    ? `md:col-start-${
                                          swap === '1'
                                              ? rightCol + 1
                                              : rightCol + 1
                                      } md:pl-8`
                                    : 'md:pr-8'
                            } `}
                        >
                            <div className="flex items-center justify-end min-h-full">
                                <div
                                    className={
                                        isWide === '1' ? 'lg:max-w-580' : ''
                                    }
                                >
                                    {heading && (
                                        <h4
                                            className="mb-1 font-medium text-20 leading-25"
                                            dangerouslySetInnerHTML={{
                                                __html: heading
                                            }}
                                        />
                                    )}
                                    {mainHeading && (
                                        <div
                                            className="mb-4 font-semibold text-25 leading-28 text-primary-500"
                                            dangerouslySetInnerHTML={{
                                                __html: mainHeading
                                            }}
                                        />
                                    )}
                                    {mainContent && (
                                        <div
                                            className="mb-10 font-medium text-17 leading-30"
                                            dangerouslySetInnerHTML={{
                                                __html: mainContent
                                            }}
                                        />
                                    )}

                                    <div className="inline-block text-center">
                                        {cta &&
                                            cta.map((item) => (
                                                <>
                                                    {item.video_modal ===
                                                    '1' ? (
                                                        <Modal
                                                            isOpen={modalIsOpen}
                                                            onRequestClose={() => {
                                                                setIsOpen(
                                                                    false
                                                                );
                                                            }}
                                                            style={customStyles}
                                                        >
                                                            <ReactPlayer
                                                                url={
                                                                    item.link
                                                                        .url
                                                                }
                                                                autoplay
                                                                controls
                                                            />
                                                        </Modal>
                                                    ) : (
                                                        ''
                                                    )}
                                                    <a
                                                        onClick={(e) =>
                                                            item.video_modal ===
                                                            '1'
                                                                ? handleModal(e)
                                                                : ''
                                                        }
                                                        target={
                                                            item.link.target
                                                        }
                                                        href={item.link.url}
                                                        className="flex items-center justify-center py-2 mb-6 font-semibold text-white uppercase transition px-14 bg-primary-500 md:text-20 text-16 rounded-30 hover:bg-primary hover:text-white"
                                                    >
                                                        {item.link.title}
                                                        {item.video_modal ===
                                                        '1' ? (
                                                            <img
                                                                src="/images/play.svg"
                                                                alt=""
                                                                className="w-4 ml-3"
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </a>
                                                </>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`md:col-span-${rightCol} ${
                                swap === '1'
                                    ? `md:col-start-0 md:col-end-${
                                          swap === '1'
                                              ? rightCol + 1
                                              : leftCol + 1
                                      }`
                                    : ''
                            }`}
                        >
                            {images.length > 1 ? (
                                <Swiper
                                    pagination={{ 
                                        clickable: true,
                                        type: 'bullets' }}
                                    slidesPerView={1}
                                    navigation
                                >
                                    {images.map((image, i) => (
                                        <SwiperSlide key={i}>
                                            <img
                                                src={
                                                    image.image.mediaItemUrl ||
                                                    image.image
                                                }
                                                className="block w-full max-w-full mx-auto"
                                                alt={image.image.altText}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            ) : (
                                images.map((image) => (
                                    <img
                                        src={
                                            image.image.mediaItemUrl ||
                                            image.image
                                        }
                                        className="block w-11/12 lg:w-full mx-auto"
                                        alt={image.image.altText}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
