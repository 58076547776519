import React from 'react';
import PropTypes from 'prop-types';

export const IconGrid = ({
    sectionTitle,
    title,
    className,
    anchor,
    items = []
}) => {
    return (
        <div
            className={`grid bg-gray-100 shadow-b items-center py-16 ${className ? className : ''}`}
            id={anchor ? anchor : ''}
        >
            <div className="container text-center md:text-left">
                <h3
                    className="mb-1 font-semibold text-20 leading-25"
                    dangerouslySetInnerHTML={{
                        __html: sectionTitle
                    }}
                />
                <div
                    className="font-medium mb-14 text-25 leading-31 text-primary-500"
                    dangerouslySetInnerHTML={{
                        __html: title
                    }}
                />
                <div className="flex flex-col items-center justify-center gap-8 md:grid-cols-12">
                    {items.map((item) => (
                        <div className="items-center gap-10 md:flex lg:w-7/12 md:px-10">
                            <div className="inline-flex items-center justify-center border-2 border-white flex-0 rounded-circle min-w-28 min-h-28 w-28 h-28">
                                <img
                                    src={item.image_icon.mediaItemUrl}
                                    alt=""
                                    className="block mx-auto"
                                />
                            </div>
                            <p
                                className="flex-1 font-medium text-16.6 leading-30"
                                dangerouslySetInnerHTML={{
                                    __html: item.text_area
                                }}
                            />
                        </div>
                    ))}
                </div>
                <div className="mt-10 text-center">
                    <a
                        href="#order-the-test"
                        className="inline-block px-6 py-2 font-semibold text-white uppercase transition bg-primary-500 text-20 rounded-30 hover:bg-primary hover:text-white"
                    >
                        Offer The Test
                    </a>
                </div>
            </div>
        </div>
    );
};
