import React from 'react';
import NextNavLink from '../../util/NextNavLink';

export default function Footer(props) {
    const { menus, options } = props;
    let menuItems = [];
    let social = options.acf_site_options.socialMediaLinks;
    if (menus['footer-menu']) {
        menuItems = menus['footer-menu'].menuItems;
    }
    let getPathOrUrl = (menuItem) => {
        return menuItem.url.includes('wp-content')
            ? menuItem.url
            : menuItem.path;
    };

    return (
        <footer
            className="py-16 text-white bg-cover"
            style={{ backgroundImage: `url(/images/footer-pattern.png)` }}
        >
            <div className="px-10 container">
                <div className="grid gap-3 md:grid-cols-12">
                    <div className="md:col-span-5 sm:col-span-12">
                        <img
                            src="/images/cirtlogowhite.png"
                            className="block max-w-xs"
                        />
                    </div>
                    {menuItems &&
                        menuItems.map((item, parentIndex) => (
                            <div
                                key={parentIndex}
                                className={
                                    parentIndex === 1
                                        ? `md:col-span-3 md:pl-4`
                                        : `col-span-2`
                                }
                            >
                                {/* <div className="pb-1 font-semibold lg:text-24 text-20 leading-33">
                                    {item.title}
                                </div> */}
                                {/* <div className="max-w-xs h-1 mb-2 border-b-2 border-white"/> */}
                                <ul className="text-white text-10 md:text-12 lg:text-16 leading-16 lg:leading-33">
                                    {item.children &&
                                        item.children.map((child, i) => (
                                            <li
                                                key={i}
                                                className={child.cssClasses}
                                            >
                                                <NextNavLink
                                                    key={i}
                                                    href={getPathOrUrl(
                                                        child
                                                    )}
                                                    target={child.target}
                                                >
                                                    <a className="">
                                                        {child.title}
                                                    </a>
                                                </NextNavLink>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        ))}
                </div>
                <div className="items-center justify-between gap-4 mt-20 md:flex">
                    <div className="flex items-center gap-4 md:order-2">
                        {social.map((item, i) => (
                            <a
                                target="_blank"
                                key={i}
                                href={item.link}
                                className=""
                            >
                                <img src={item.icon.mediaItemUrl} alt="" />
                            </a>
                        ))}
                    </div>
                    <p className="text-16.6 leading-20 md:mt-0 mt-10  md:order-1">
                        <span className="text-14">&copy;</span>{' '}
                        <span>{new Date().getFullYear()}</span> Oxford
                        BioDynamics, Plc. All rights reserved. EpiSwitch is a
                        registered trademark of Oxford BioDynamics, Plc.
                    </p>
                </div>
            </div>
        </footer>
    );
}
