import React from 'react';

export const ImageText = ({
    title,
    content,
    image,
    className,
    anchor = '',
    align = 'center',
    vAlign = 'center'
}) => {
    return (
        <div
            id={anchor}
            className={`bg-center relative flex items-${
                vAlign === 'bottom' ? 'end' : ''
            } bg-cover ${vAlign === 'bottom' ? 'py-72 pb-0' : 'py-60 pb-0'}  ${
                className ? className : ''
            }`}
            style={{
                backgroundImage: `url(${image.mediaItemUrl || image})`
            }}
        >
            <div
                className={`container bg-cover ${
                    vAlign === 'bottom' ? 'lg:mb-14 lg:mt-10' : 'mb-10 lg:mb-24'
                }  ${className ? className : ''}`}
            >
                <div className={`flex items-center justify-center `}>
                    <div className="w-8/12 ml-24 2xl:w-1/3 lg:w-5/12 md:w-7/12 lg:px-2">
                        <p
                            className="text-28 mb-28 pb-20 lg:text-24 md:text-18"
                            dangerouslySetInnerHTML={{
                                __html: content
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
