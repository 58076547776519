import React from 'react';
import { TwoColumn } from '../../components/cst/TwoColumn';
import { extractRepeaterField } from '../../util/blocks';

function ACFTwoColumn({ className, anchor, data, pageProps }) {
    const {
        group_1_main_heading,
        group_1_text_area,
        group_1_top_heading,
        group_2_top_heading,
        group_2_main_heading,
        group_2_text_area,
        group_2_swap_content,
        style_options_wide_column,
        style_options_style_width
    } = data;

    let images = extractRepeaterField(data, 'group_2_images');
    let progressBars = extractRepeaterField(data, 'progress_bars');

    let links = extractRepeaterField(data, 'group_2_links');
    images = images.map((repeaterItem) => ({
        ...repeaterItem,
        image: pageProps.mediaItems[repeaterItem.image] || {}
    }));

    return (
        <>
            <TwoColumn
                sectionTitle={group_1_main_heading}
                title={group_1_top_heading}
                description={group_1_text_area}
                heading={group_2_top_heading}
                mainHeading={group_2_main_heading}
                mainContent={group_2_text_area}
                swap={group_2_swap_content}
                cta={links}
                images={images}
                isWide={style_options_wide_column}
                colSize={style_options_style_width}
                progressBars={progressBars}
                className={className}
                anchor={anchor}
            />
        </>
    );
}

export default ACFTwoColumn;
