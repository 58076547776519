import React, { useCallback, useEffect, useRef } from 'react';
import { Contact } from '../../components/cst/Contact';
import { extractRepeaterField } from '../../util/blocks';

function ACFAnimatedBanner(props) {
    const { className, anchor, data, pageProps, meta } = props;
    const { top_heading, text_block_1, loop } = data;
    const [backgroundGif, setBackgroundGif] = React.useState(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const intervalRef = useRef(null);
    const animationRef = useRef(null);
    let steps = extractRepeaterField(data, 'steps');
    const sliderCallback = useCallback(
        (interval) => {
            setActiveStep((activeStep) => {
                if (data.loop === '0' && activeStep === steps.length - 1) {
                    clearInterval(interval);
                    return activeStep;
                }
                if (activeStep === steps.length - 1) {
                    animationRef.current.classList.remove('animate');
                    void animationRef.current.offsetWidth;
                    animationRef.current.classList.add('animate');
                }
                return (activeStep + 1) % steps.length;
            });
        },
        [activeStep]
    );

    useEffect(() => {
        // loop through steps and set active step each 5sec

        intervalRef.current = setInterval(
            () => sliderCallback(intervalRef.current),
            5000
        );
        if (pageProps.mediaItems[data.gif]) {
            setBackgroundGif(pageProps.mediaItems[data.gif].mediaItemUrl);
        }
        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);
    return (
        <section
            data-name="animated-banner"
            ref={animationRef}
            className="banner--animated animate"
            style={{
                '--background-image': `url("${backgroundGif}")`
            }}
        >
            <div className="banner--container">
                <ul className="banner--steps-wrapper">
                    {steps &&
                        steps.map((step, index) => (
                            <BannerStep
                                step={step}
                                key={index}
                                active={activeStep === index}
                            />
                        ))}
                </ul>
            </div>
        </section>
    );
}

function BannerStep({ step, active }) {
    return (
        <li className={`step ${!active ? 'step--hide' : 'step--active'}`}>
            <div className="flex flex-col items-center justify-center">
                <h3 className="step--content">{step.content}</h3>
                {step.cta?.title ? (
                    <a href={step.cta.url} className="step--cta">
                        {step.cta.title}
                    </a>
                ) : (
                    <></>
                )}
            </div>
        </li>
    );
}
export default ACFAnimatedBanner;
