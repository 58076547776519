import React from 'react';
import { TwoColumnGrid } from '../../components/cst/TwoColumnGrid';
import { extractRepeaterField } from '../../util/blocks';

function ACFTwoColumnGrid({ className, anchor, data, pageProps }) {
    const { top_heading, main_heading } = data;
    let items = extractRepeaterField(data, 'grid');
    items = items.map((repeaterItem) => ({
        ...repeaterItem,
        image_icon: pageProps.mediaItems[repeaterItem.image_icon] || {}
    }));
    const cta = extractRepeaterField(data, 'cta');
    // const imageFile = pageProps.mediaItems[image] || {};

    return (
        <>
            <TwoColumnGrid
                sectionTitle={top_heading}
                title={main_heading}
                cta={cta}
                items={items}
                className={className}
                anchor={anchor}
            />
        </>
    );
}

export default ACFTwoColumnGrid;
