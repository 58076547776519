import React from 'react';

export const ImageTextRight = ({
    title,
    content,
    image,
    className,
    anchor = '',
    align = 'center',
    vAlign = 'right',
    cta,
    text_position
}) => {

    return (
        <div>
            {text_position === 'left' && (
                <div
                    id={anchor}
                    className={`relative bg-center flex justify-end items-${
                        vAlign === 'bottom' ? 'end' : ''
                    } bg-cover ${
                        vAlign === 'bottom' ? 'py-60 pb-48' : 'py-48 pb-48'
                    }  ${className ? className : ''}`}
                    style={{
                        backgroundImage: `url(${image.mediaItemUrl || image})`
                    }}
                >
                    <div className="container text-left md:text-left">
                        <div
                            className="font-medium mb-4 text-25 leading-28 text-primary-500"
                            dangerouslySetInnerHTML={{
                                __html: content
                            }}
                        />
                        <h3
                            className="mb-1 font-semibold text-16 leading-28 pb-12"
                            dangerouslySetInnerHTML={{
                                __html: title
                            }}
                        />
                        {cta && (
                            <div>
                                <a
                                    target={cta.target}
                                    href={cta.url}
                                    className="inline-block px-4 py-2 font-bold text-white uppercase transition border-white shadow-md border-3 md:text-20 text-16 rounded-30 text-shadow hover:bg-white hover:text-primary"
                                >
                                    {cta.title}
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {text_position === 'right' && (
                <div
                    id={anchor}
                    style={{
                        backgroundImage: `url(${image.mediaItemUrl || image})` //add media breakpoints here?
                    }}
                    className={`relative flex lg:justify-end justify-center items-center ${
                        vAlign === 'bottom' ? 'end' : ''
                    } bg-cover 2xl:bg-auto bg-no-repeat sm:max-h-[500px]  
                    ${className ? className : ''}`}
                >
                    <div
                        className={`my-2 py-2 lg:my-0 lg:py-0 lg:justify-self-end justify-center flex items-center lg:w-1/2 w-2/3`}
                    >
                        <div className="text-left md:text-left h-auto">
                            <div className="md:py-14 md:pr-14 bg-white bg-opacity-75 px-2 my-6 md:pl-6 2xl:w-7/12 w-3/4">
                                <h3
                                    className="mb-1 font-semibold text-20 leading-36 xl:pr-18 xl:mr-18 pr-2 mr-0 pl-4 pt-2"
                                    dangerouslySetInnerHTML={{
                                        __html: title
                                    }}
                                />
                                <div
                                    className="font-medium mb-8 text-25 leading-36 text-primary-500 xl:pr-18 xl:mr-18 pr-2 mr-0 pl-4"
                                    dangerouslySetInnerHTML={{
                                        __html: content
                                    }}
                                />

                                {cta && (
                                    <div className="inline-block text-center xl:pr-20 md:pr-2 md:mr-0 md:pl-4">
                                        <a
                                            target={cta.target}
                                            href={cta.url}
                                            className="flex items-center justify-center px-2 md:px-20 py-2 mb-6 font-semibold text-white transition bg-primary-500 md:text-20 text-16 rounded-30 hover:bg-primary hover:text-white"
                                        >
                                            {cta.title}
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
