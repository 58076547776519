import React from 'react';
import { ImageText } from '../../components/cst/ImageText';
import { extractRepeaterField } from '../../util/blocks';

function ACFImageText({ className, anchor, data, pageProps }) {
    const {
        top_heading,
        main_heading,
        desktop_image,
        style_options_style_content_align,
        style_options_text_vertical_align
    } = data;

    let links = extractRepeaterField(data, 'links');

    const imageFile = pageProps.mediaItems[desktop_image] || {};

    return (
        <>
            <ImageText
                title={top_heading}
                content={main_heading}
                className={className}
                anchor={anchor}
                image={imageFile}
                align={style_options_style_content_align}
                vAlign={style_options_text_vertical_align}
            />
        </>
    );
}

export default ACFImageText;
