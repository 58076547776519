import React from 'react';
import { extractRepeaterField } from '../../util/blocks';
import { ImageTextRight } from '../../components/cst/ImageTextRight';

function ACFImageTextRight({ className, anchor, data, pageProps }) {
    const {
        top_heading,
        main_heading,
        full_width_image,
        style_options_style_content_align,
        style_options_text_vertical_align,
        cta,
        text_position
    } = data;

    let links = extractRepeaterField(data, 'links');
    
    const imageFile = pageProps.mediaItems[full_width_image] || {};

    return (
        <>
            <ImageTextRight
                title={top_heading}
                content={main_heading}
                className={className}
                anchor={anchor}
                image={imageFile}
                align={style_options_style_content_align}
                vAlign={style_options_text_vertical_align}
                cta={cta}
                text_position={text_position}
            />
        </>
    );
}

export default ACFImageTextRight;
