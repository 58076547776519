import React from 'react';
import { Hero } from '../../components/cst/Hero';
import { extractRepeaterField } from '../../util/blocks';

function ACFHero({ data, pageProps }) {
    const { top_header, title, text_area, main_image, cta, cta_video } = data;

    let pop_up_ctas = extractRepeaterField(data, 'pop_up_ctas');
    
    const imageFile = pageProps.mediaItems[main_image] || {};
    
    pop_up_ctas.map(cta => {
       cta.image = pageProps.mediaItems[cta.image]; 
    });
    
    return (
        <>
            <Hero
                top_header={top_header}
                title={title}
                image={imageFile.mediaItemUrl}
                text={text_area}
                cta={cta}
                cta_video={cta_video}
                pop_up_ctas={pop_up_ctas}
            />
        </>
    );
}

export default ACFHero;
